<template>
    <div class="additional-service">
        <template v-if="service.type === 'radio'">
            <modal-field :label="service.label" :class="bodyClass">
                <label
                    v-for="option in service.options"
                    :key="`service-${service.id}-option-${option.id}`"
                    class="mr-2"
                >
                    <input
                        v-model="service.value"
                        :value="option.value"
                        :name="service.name"
                        type="radio"
                        @change="updateAdditionalServiceById(service)"
                    >
                    {{ option.label }}
                </label>
            </modal-field>
        </template>
        <template v-else-if="service.type === 'tel'">
            <modal-field :label="service.label" :class="bodyClass">
                <the-mask
                    mask="+#############"
                    class="mt-1"
                    type="tel"
                    :value="service.value"
                    placeholder="Phone"
                    @input="updateAdditionalServiceById({ ...service, value: $event })"
                />
            </modal-field>
        </template>
        <template v-else-if="service.type === 'checkbox' && !service.options.length">
            <modal-field body-class="flex" class="py-2" :class="bodyClass">
                <label class="flex cursor-pointer">
                    <checkbox
                        v-model="service.value"
                        @change="updateAdditionalServiceById(service)"
                    />
                    {{ service.label }}
                </label>
            </modal-field>
        </template>
        <template v-else-if="service.type === 'select'">
            <modal-field :label="service.label">
                <multiselect
                    v-model="service.value"
                    :options="service.options"
                    track-by="value"
                    label="label"
                />
            </modal-field>
        </template>
        
        <template v-if="service.children && service.children.length">
            <additional-service
                v-for="child in service.children"
                :key="`additional-service-child_${child.id}`"
                :service="child"
                :body-class="bodyClass"
            />
        </template>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'AdditionalService',
    components: { Multiselect },
    props: {
        service: {
            type: Object,
            default: () => ({}),
        },
        bodyClass: String,
    },
    data() {
        return {
            timer: null,
        };
    },
    methods: {
        updateAdditionalServiceById(params) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            
            if (params.type === 'tel') {
                this.timer = setTimeout(() => {
                    this.$store.dispatch('price-leadtime/updateAdditionalServiceById', params);
                }, 800);

                return;
            }

            this.$store.dispatch('price-leadtime/updateAdditionalServiceById', params);
        },
    },
}
</script>

<style>

</style>