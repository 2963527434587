<template>
    <div class="relative hint">
        <div
            v-show="value && isHovering"
            :style="`top: ${coords.top}px;`"
            class="hint__block border-radius"
        >
            <slot>
                {{ value }}
            </slot>
        </div>
    </div>
</template>

<script>
const DEFAULT_COORDS = { left: 0, top: 0 };

function getCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top + scrollTop - clientTop - window.scrollY;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

export default {
    name: 'Hint',
    props: {
        value: String,
        targetElement: String,
    },
    data() {
        return {
            coords: DEFAULT_COORDS,
            listenerTarget: null,
            isHovering: false,
        }
    },
    methods: {
        listener(event) {
            if (!this.isHovering) {
                this.isHovering = true;
            }

            this.coords.top = event.clientY - getCoords(this.listenerTarget).top;
        },
        onMouseLeave() {
            this.isHovering = false;
            this.coords = DEFAULT_COORDS;
        },
        setListenerTarget() {
            try {
                this.listenerTarget = this.targetElement ? this.$parent.$el.getElementsByClassName(this.targetElement)[0] : this.$el;
            } catch(error) {
                console.error(error)
            }
        },
    },
    mounted() {
        this.setListenerTarget();

        this.listenerTarget.addEventListener('mousemove', this.listener);
        this.listenerTarget.addEventListener('mouseleave', this.onMouseLeave);
    },
    beforeDestroy() {
        this.listenerTarget.removeEventListener('mousemove', this.listener);
        this.listenerTarget.removeEventListener('mouseleave', this.onMouseLeave);
    },
}
</script>

<style lang="scss">
.hint {
    &__block {
        padding: 10px;
        max-width: 300px;
        width: 100%;
        position: absolute;
        left: 105%;
        background: var(--color-white);
        border: 1px solid var(--color-border);
        z-index: 99999;
    }
}
</style>