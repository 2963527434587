<template>
    <card class="flex plt-carrier-wrapper" body-class="flex-wrap">
        <div class="w-1/3">
            <modal-field
                label="Carrier"
                class="mr-4"
                :class="{ empty: getIsEmptyField(priceLeadTimeResult.carrier_model) }"
                required
            >
                <multiselect
                    :value="priceLeadTimeResult.carrier_model"
                    :options="options.carrier"
                    :disabled="getIsLockedField('carrier_service') || isUnifaunTemplateWithService"
                    label="name"
                    track-by="id"
                    @select="updateCarrier"
                >
                </multiselect>
            </modal-field>
        </div>
        <div class="w-1/3">
            <modal-field
                id="service-multiselect"
                label="Carrier service"
                class="mr-4"
                :class="{ empty: getIsEmptyField(priceLeadTimeResult.carrier_service_model) }"
                required
            >
                <multiselect
                    :value="priceLeadTimeResult.carrier_service_model"
                    :options="options.carrier_service"
                    :disabled="getIsLockedField('carrier_service') || isUnifaunTemplateWithService"
                    track-by="id"
                    label="name"
                    @select="updateCarrierService"
                    @open="isServicesOpen = true"
                    @close="isServicesOpen = false"
                >
                    <template slot="noOptions">
                        <div class="hint-option">
                            No services found
                        </div>
                    </template>
                    <template slot="noResult">
                        <div class="hint-option">
                            No services found
                        </div>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="hint-option" @mouseenter="setHintValue(props.option)">{{ props.option.name }}</div>
                    </template>
                </multiselect>
                <hint v-if="isServicesOpen" target-element="multiselect__content-wrapper" :value="hintValue" />
            </modal-field>
        </div>
        <div v-if="isShortFlow || isTemplate && additionalServices.length" class="w-full mt-4">
            <additional-service
                v-for="service in additionalServices"
                :key="`additional-service_${service.id}`"
                :service="service"
            />
        </div>
    </card>
</template>
<script>
import axios from 'axios';

import lockedMixin from './locked.mixin.js';
import PltMixin from '~/pages/transport/pltMixin.js';
import IsTemplateMixin from '~/mixins/isTemplate.mixin';
import AdditionalServicesMixin from '~/mixins/addServices.mixin.js';

import Hint from '~/components/Hint.vue';
import Multiselect from 'vue-multiselect';
import AdditionalService from '~/components/PriceLeadTime/AdditionalService.vue';

export default {
    name: 'PriceLeadtimeCarrier',
    mixins: [lockedMixin, PltMixin, IsTemplateMixin, AdditionalServicesMixin],
    components: {
        Multiselect,
        Hint,
        AdditionalService,
    },
    data: () => ({
        submitter: false,
        carrier_service_all: [],
        options: {
            carrier: [],
            carrier_service: [],
        },
        isBlockSideEffects: true,
        hintValue: '',
        isServicesOpen: false,
    }),
    computed: {
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        priceLeadTimeResult() {
            return this.$store.getters['price-leadtime/getPriceLeadTimeResult'];
        },
        currentTemplate() {
            return this.$store.getters['price-leadtime/currentTemplate'];
        },
        isUnifaunTemplateWithService() {
            return Boolean(this.currentTemplate?.unifaun_pk && this.currentTemplate?.carrier_service_id);
        },
    },
    watch: {
        priceLeadTimeResult: {
            deep: true,
            handler(val) {
                if (val.carrier_service_model?.id) {
                    this.$emit('update:carrier-service', val.carrier_service_model?.id);
                }
            },
        },
        addServicesTriggers: {
            deep: true,
            async handler(value) {
                if (!this.isShortFlow && !this.isTemplate || !value.carrierServiceId) {
                    return;
                }

                this.fetchAddServices();
            }
        },
    },
    methods: {
        setHintValue(data) {
            this.hintValue = data.service_description?.service_description || '';
        },
        updateCarrier(data) {
            this.carrier = data;

            this.$store.commit('price-leadtime/SET_CARRIER_MODEL', data || {});
            this.updateCarrierService(null);
            this.options.carrier_service = this.carrier_service_all.filter(service => service.carrier_id === this.carrier?.id);
            this.$store.commit('price-leadtime/SET_ADDITIONAL_SERVICES', []);
            this.$store.commit('price-leadtime/LAST_CHANGED_ADDITIONAL_SERVICE', null);
        },
        updateCarrierService(data) {
            this.carrier_service = data;

            this.$store.commit('price-leadtime/SET_CARRIER_SERVICE_MODEL', data);
            this.$store.commit('price-leadtime/LAST_CHANGED_ADDITIONAL_SERVICE', null);
        },
        async initOptions() {
            const promises = [
                axios.get(this.$apiUrl.carriers.base),
                axios.get(this.isTemplate || this.isGodUser ? `${this.$apiUrl.carrierServices.base}?limit=10000` : this.$apiUrl.carrierServices.limited),
            ];

            const result = await Promise.all(promises);

            this.carrier_service_all = result[1]?.data?.data || [];
            this.options.carrier = (result[0]?.data?.data || [])
                .filter(
                    carrier => 
                    this.carrier_service_all.find(service => service.carrier_id === carrier.id)
                );

            if (!this.carrier && this.priceLeadTimeResult?.carrier_model?.id) {
                this.carrier = this.options.carrier.find(carrier => carrier.id === this.priceLeadTimeResult.carrier_model.id);
                this.options.carrier_service = this.carrier_service_all.filter(service => service.carrier_id === this.carrier?.id);
            }

            if (!this.carrier_service && this.priceLeadTimeResult?.carrier_service_model?.id) {
                this.priceLeadTimeResult.carrier_service_model = this.carrier_service_all.find(carrier => carrier.id === this.priceLeadTimeResult.carrier_service_model.id);
                this.$emit('update:carrier-service-initial', this.priceLeadTimeResult.carrier_service_model?.id);
            }

            this.isBlockSideEffects = false;
        },
        clearForm() {
            this.priceLeadTimeResult.carrier_model = null,
            this.priceLeadTimeResult.carrier_service_model = null,
            this.options.carrier_service = [];
        },
    },
    created() {
        this.initOptions();
    },
}
</script>
<style lang="scss">
.plt-carrier-wrapper {
    .card-body {
        overflow: unset;
    }

    #service-multiselect {
        .multiselect__option {
            padding: 0 !important;
        }
        .hint-option {
            padding: 12px 12px 12px 1rem;
        }
    }
}
</style>